import Api from '@/services/Api'

export default {
  insert (historicoMovimentacao) {
    return Api().post('histmovest', historicoMovimentacao)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('histmovest/findByParam', param)
  },
  findById (objeto) {
    const url = `histmovest/findById/${objeto.id}`
    return Api().get(url)
  },

}
