import Api from '@/services/Api'

export default {
  findByItemDeposito (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('estoque/findByItemDeposito', param)
  }

}
