<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="$t('padrao_deseja_remover_registro')"
    :mostra-botao-salvar-continuar="false"
    :mostra-botao-deletar="false"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
  >
    <v-container class="py-0">
      <v-row>
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
            :readonly="!!empresaClienteModelId || isEditting || readOnly"
            :clearable="!isEditting"
          />
        </v-col>
        <v-col
          :cols="12"
          sm="6"
        >
          <v-autocomplete
            v-model="autoCompleteDepositoModel"
            :readonly="!empresaClienteModelId || readOnly"
            :items="autoCompleteDepositoItens"
            :loading="autoCompleteDepositoLoading"
            :search-input.sync="autoCompleteDepositoCampoBusca"
            :filter="filterDepositoAc"
            :label="$t('HistoricoMovimentacaoEstoqueList.deposito')"
            hide-no-data
            item-text="nome"
            item-value="id"
            :placeholder="$t('padrao_digite_para_buscar')"
            prepend-icon="mdi-database-search"
            return-object
            clearable
            required
            :rules="[required]"
          />
        </v-col>
        <v-col
          :cols="12"
          sm="6"
        >
          <v-autocomplete
            v-model="autoCompleteItemModel"
            :readonly="!empresaClienteModelId || readOnly"
            :items="autoCompleteItemItens"
            :loading="autoCompleteItemLoading"
            :search-input.sync="autoCompleteItemCampoBusca"
            :filter="filterItemAc"
            :label="$t('HistoricoMovimentacaoEstoqueList.item')"
            hide-no-data
            item-text="nomeItem"
            item-value="id"
            :placeholder="$t('padrao_digite_para_buscar')"
            prepend-icon="mdi-database-search"
            return-object
            clearable
            required
            :rules="[required]"
          />
        </v-col>
        <v-col
          :cols="12"
          md="4"
        >
          <v-select
            v-model="objEditar.origemMovimentacao"
            :readonly="readOnly"
            :rules="[required]"
            :label="readOnly ? $t('HistoricoMovimentacaoEstoqueList.origemMovimentacao') : $t('HistoricoMovimentacaoEstoqueList.tipo_movimentacao')"
            :items="listOrigemMovimentacao"
            item-value="value"
            item-text="text"
            required
            prepend-icon="mdi-format-list-bulleted-type"
          />
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="objEditar.quantidadeMovimentada"
            :readonly="readOnly"
            :label="$t('HistoricoMovimentacaoEstoqueList.quantidadeMovimentada')"
            class="purple-input"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="quantidadeAtualComputed"
            :label="$t('HistoricoMovimentacaoEstoqueList.quantidadeAtual')"
            class="purple-input"
            type="number"
            readonly
            :loading="loading"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="objEditar.motivoHistorico"
            :readonly="readOnly"
            :label="$t('HistoricoMovimentacaoEstoqueList.motivoHistorico')"
            class="purple-input"
            prepend-icon="mdi-account-edit"
            counter
            outlined
          />
        </v-col>
      </v-row>
    </v-container>
  </crud-form>
</template>

<script>

  import Servico from '../services/HistoricoMovimentacaoEstoqueService'
  import EstoqueService from '@/services/EstoqueService'
  import { mapState } from 'vuex'
  import DateUtils from '../utils/DateUtils'
  import EmpresaClienteAutoComplete from '@/views/EmpresaClienteAutoComplete'
  import EmpresaClientesService from '@/services/EmpresaClientesService'
  import ItemService from '@/services/ItemService'
  import DepositosService from '@/services/DepositosService'

  export default {
    components: {
      EmpresaClienteAutoComplete
    },
    data: () => ({
      menuDataCompra: false,
      empresaClienteModel: null,
      empresaClienteModelId: null,
      autoCompleteItemModel: null,
      autoCompleteItemItens: [],
      autoCompleteItemLoading: false,
      autoCompleteItemCampoBusca: '',
      autoCompleteDepositoModel: null,
      autoCompleteDepositoItens: [],
      autoCompleteDepositoLoading: false,
      autoCompleteDepositoCampoBusca: '',
      depositoModelId: null,
      itemModelId: null,
      loading: false,
      readOnly: false,
      backUrl: null,
      tipoMovimentacao: 'final',
      objPadrao: {
        idItem: 0,
        idDeposito: true,
        quantidadeMovimentada: null,
        motivoHistorico: '',
        origemMovimentacao: 0,
        idUsuarioAlteracao: null,
        idEmpresaCliente: null

      },
      objEditar: {
        idItem: 0,
        idDeposito: true,
        quantidadeMovimentada: null,
        motivoHistorico: '',
        origemMovimentacao: 0,
        idUsuarioAlteracao: null,
        idEmpresaCliente: null
      },
      isEditting: true,
      quantidadeAtual: 0
    }),
    computed: {
      quantidadeAtualComputed () {
        return parseFloat(this.quantidadeAtual)
      },
      required: function(value) { return !!value || this.$i18n.t('padrao_campo_obrigatorio')},
      ...mapState(['idEmpresaCliente']),
      tituloPagina () {
        return this.$i18n.t('HistoricoMovimentacaoEstoqueList.titulo')
      },
      dataCompraFormatted: function () {
        return DateUtils.formatDate(this.objEditar.dataCompra);
      },
      listOrigemMovimentacao: function () {
        const retorno = [
          {
            text: this.$i18n.t('HistoricoMovimentacaoEstoqueList.origemMovimentacao_0'),
            value: 0
          },
          {
            text: this.$i18n.t('HistoricoMovimentacaoEstoqueList.origemMovimentacao_1'),
            value: 1
          },
          {
            text: this.$i18n.t('HistoricoMovimentacaoEstoqueList.origemMovimentacao_2'),
            value: 2
          }
        ]
        
        return retorno.sort(function(a,b){
          if (a.text > b.text) {
            return 1;
          }
          if (a.text < b.text) {
            return -1;
          }
          return 0;
        })
      }
    },
    watch: {
      autoCompleteItemModel(val) {
        if (val === null || !val) {
          this.itemModelId = null
          this.objEditar.idItem = null
        } else {
          this.itemModelId = val.id
          this.objEditar.idItem = val.id
        }
        this.buscarSaldoEstoque()
      },
      autoCompleteDepositoModel(val) {
        if (val === null || !val) {
          this.depositoModelId = null
          this.objEditar.idDeposito = null
        } else {
          this.depositoModelId = val.id
          this.objEditar.idDeposito = val.id
        }
        this.buscarSaldoEstoque()
      },
      autoCompleteItemCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaItem(val)
      },
      autoCompleteDepositoCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaDeposito(val)
      },
      empresaClienteModel (val) {
        if (val === null || !val) {
          this.objEditar.EmpresaCliente = null
          this.objEditar.idEmpresaCliente = null
          this.empresaClienteModelId = null
          this.autoCompleteItemModel= null
          this.autoCompleteItemItens= []
          this.autoCompleteItemLoading= false
          this.autoCompleteItemCampoBusca= ''
          this.autoCompleteDepositoModel= null
          this.autoCompleteDepositoItens= []
          this.autoCompleteDepositoLoading= false
          this.autoCompleteDepositoCampoBusca= ''
        } else {
          this.objEditar.EmpresaCliente = val
          this.objEditar.idEmpresaCliente = val.id
          this.empresaClienteModelId = val.id
        }
      }

    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      this.backUrl = this.$route.params.backUrl
      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = Object.assign({}, this.objPadrao)

        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          EmpresaClientesService.findById(this.idEmpresaCliente).then((res) => {
            this.objEditar.EmpresaCliente = res.data
            this.empresaClienteModel = res.data
            this.empresaClienteModelId = this.empresaClienteModel.id
          })
        }
      }
    },
    methods: {

      buscarSaldoEstoque () {
        if(!this.idEmpresaCliente || this.idEmpresaCliente === 0){
          return
        }

        if(!this.objEditar.idItem || !this.objEditar.idDeposito){
          return 
        }

        this.loading = true

        const filtro = {
        }
        filtro.idEmpresaCliente =
          this.empresaClienteModelId || this.idEmpresaCliente
        
        filtro.idDeposito = this.depositoModelId
        filtro.idItem = this.itemModelId

        this.quantidadeAtual = 0

        EstoqueService.findByItemDeposito(filtro, this.pagination)
          .then((dados) => {
            if (dados && dados.data && dados.data.rows && dados.data.rows.length > 0) {
              console.log('rows -> ',dados.data.rows)
              this.quantidadeAtual = dados.data.rows[0].quantidadeEstoque
            }
          })
          .catch(err => {
            console.log('errou -> ', err)
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },

      buscaDeposito (param) {
        this.autoCompleteDepositoLoading = true
        DepositosService.buscaDepositosPorParam(
          param,
          this.objEditar.idEmpresaCliente
        )
          .then((depositos) => {
            this.autoCompleteDepositoItens = depositos.data
          })
          .catch((error) => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro na busca dos Depositos ' + error
            })
          })
          .finally(() => {
            this.autoCompleteDepositoLoading = false
          })
      },
      filterDepositoAc (item, queryText, itemText) {
        return true
      },
      buscaItem (param) {
        this.autoCompleteItemLoading = true
        ItemService.findByParamAutoComplete(
          param,
          this.objEditar.idEmpresaCliente
        )
          .then((itens) => {
            this.autoCompleteItemItens = itens.data
          })
          .catch((error) => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro na busca dos Itens ' + error
            })
          })
          .finally(() => {
            this.autoCompleteItemLoading = false
          })
      },
      filterItemAc (item, queryText, itemText) {
        return true
      },
      saveEventHandler () {
        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        this.inserir(this.objEditar)
      },
      closeDialog () {
        this.$router.push(`/${this.backUrl}`)
      },
      inserir (registro, manterNestaTela) {
        try {
          if (this.autoCompleteDepositoModel) {
            registro.idDeposito = this.autoCompleteDepositoModel.id
          }
          if (this.autoCompleteItemModel) {
            registro.idItem = this.autoCompleteItemModel.id
          }

          registro.idUsuarioAlteracao = JSON.parse(localStorage.arshome_auth).id

          Servico.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              if (manterNestaTela) {
                this.editar(response.data.id)
              } else {
                this.closeDialog()
              }
            }
          })
            .catch(err => {
              console.log(`errou -> `, err)
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err.response.data.error
              })
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um Despera:\n ->' + error
          })
        }
      },
      editar (id) {
        this.readOnly = true
        Servico.findById({ id })
          .then((registro) => {
            this.objEditar = registro.data
            this.isEditting = true

            this.empresaClienteModel = this.objEditar.EmpresaCliente
            this.empresaClienteModelId = this.objEditar.idEmpresaCliente
            if (this.objEditar.idDeposito) {
              DepositosService.findById({ id: this.objEditar.idDeposito }).then(
                (deposito) => {
                  if (deposito.id) {
                    this.autoCompleteDepositoModel = deposito
                    this.autoCompleteDepositoItens[0] = deposito
                    this.autoCompleteDepositoCampoBusca = deposito.nome
                  } else if (deposito.data.id) {
                    this.autoCompleteDepositoModel = deposito.data
                    this.autoCompleteDepositoItens[0] = deposito.data
                    this.autoCompleteDepositoCampoBusca = deposito.data.nome
                  }
                }
              )
            }
            if (this.objEditar.idItem) {
              ItemService.findById({ id: this.objEditar.idItem }).then(
                (item) => {
                  if (item.id) {
                    this.autoCompleteItemModel = item
                    this.autoCompleteItemItens[0] = item
                    this.autoCompleteItemCampoBusca = item.nomeItem
                  } else if (item.data.id) {
                    this.autoCompleteItemModel = item.data
                    this.autoCompleteItemItens[0] = item.data
                    this.autoCompleteItemCampoBusca = item.data.nomeItem
                  }
                }
              )
            }
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
